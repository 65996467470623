import { Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function PriorityCard() {
    const { t } = useTranslation();
    return (
        <>
            <div>
                <Grid container direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Card sx={{ maxWidth: { "xs": "100%", "md": "100%", "lg": "50%" }, margin: "0.5rem", padding: "1rem", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }} >
                        <Stack direction={{ xs: 'column', sm: 'row' }}
                            divider={<Divider orientation={{ xs: 'horizontal', sm: 'vertical' }} flexItem sx={{ bgcolor: 'divider', width: { sm: '2px' } }} />}
                            spacing={2}
                        >
                            <Grid sx={{
                            }}>
                                <Typography sx={{
                                    fontSize: "1rem",
                                    color: "#707070",
                                }}>{t('priority1')}</Typography>
                            </Grid>
                            <Grid sx={{
                            }}>
                                <Typography sx={{
                                    fontSize: "1rem",
                                    color: "#707070",

                                }}>{t('priority2')}</Typography>
                            </Grid>
                            <Grid sx={{
                            }}>
                                <Typography sx={{
                                    fontSize: "1rem",
                                    color: "#707070",
                                }}>{t('priority3')}</Typography>
                            </Grid>
                        </Stack>
                    </Card>
                </Grid>
            </div>
        </>
    )
}