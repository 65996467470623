import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Workwithusdata from "./../datajson/workwithusdata.json"
import { useTranslation } from 'react-i18next';

export default function WorkWithUsCard() {
  const { t } = useTranslation('HomePage');
  return (
    <>
      {Workwithusdata && Workwithusdata.map(workwithusdata => {
        return (
          <Card sx={{ maxWidth: { "xs": "100%", "sm": "40%", "md": "22%", "lg": "22%" }, maxHeight: "300", boxShadow: "none", textAlign: "center", borderRadius: "0px", margin: "0.5rem", paddingTop: "1rem" }}>
            <img src={workwithusdata.icon} style={{ height: "68px" }} alt='Card Icon' />
            <CardContent>
              <Typography gutterBottom variant="body1" component="div" fontWeight={"Bold"} color={"#14213D"}>
                {t(workwithusdata.name)}
              </Typography>
              <Typography variant="body1" color="text.secondary" justifyContent={"center"} >
                {t(workwithusdata.description)}
              </Typography>
            </CardContent>
          </Card>
        )
      })}
    </>
  );
}