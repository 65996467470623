import { Box, Button, Container, Grid, Typography } from "@mui/material";
import PortfolioCard from "../components/portfoliocard";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Sectionfour() {
    const { t } = useTranslation();
    return (
        <>
            <div style={{
                paddingTop: "5rem",
                paddingBottom: "5rem"
            }}>
                <Grid container justifyContent={"center"} >
                    <Typography sx={{
                        fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                        fontWeight: "bold", textAlign: "center", marginBottom: "0.8rem",
                        color: "#14213D"
                    }} >  {t('sectionfourtittle')}</Typography>
                </Grid>
                <Grid container justifyContent={"center"} >
                    <Container maxWidth="sm">
                        <Typography sx={{
                            textAlign: "center", marginBottom: "1.8rem",
                            color: "#707070"
                        }} > {t('sectionfoursubtittle')}</Typography>
                    </Container>
                </Grid>
                <Container>
                    <Box>
                        <Grid container justifyContent={"center"} >
                            <PortfolioCard />
                        </Grid>
                    </Box>

                </Container>

                <Grid container justifyContent={"center"} sx={{ marginTop: "2rem" }}>
                    <Link to="/ourwork" style={{ textDecoration: "none", }}>
                        <Button variant="outlined"
                            sx={{
                                borderRadius: 16,
                                border: 1,
                                textTransform: "none",
                                fontWeight: "bold"
                            }}>
                            {t('sectionfourbutton')}
                        </Button>
                    </Link>
                </Grid>
            </div>
        </>
    )

}