import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';


function AppNavigationBar() {
  const { t } = useTranslation('AppBar');
  const location = useLocation();
  const { pathname } = location;

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" elevation={0} >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Box
              component="img"
              sx={{
                flexGrow: 1,
                height: 30,
                mr: 0,
                maxHeight: { xs: 100, md: 100 },
                maxWidth: { xs: 100, md: 100 },
                display: { xs: "none", md: "flex" },
              }}
              alt="Oxide Digital Technology"
              src="/assets/oxide/oxidelogowidelight.svg"
            />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block", marginLeft: "1rem", marginRight: "1rem", ":hover": { color: "#EB9B29" }, color: pathname === "/" ? "#EB9B29" : "#14213D", }}>
                  <Typography textTransform={"capitalize"} >{t('home')}</Typography>
                </Button>
              </Link>
              <Link to="/services" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block", marginLeft: "1rem", marginRight: "1rem", ":hover": { color: "#EB9B29" }, color: pathname === "/services" ? "#EB9B29" : "#14213D", }}>
                  <Typography textTransform={"capitalize"} >{t('services')}</Typography>
                </Button>
              </Link>
              <Link to="/ourwork" style={{ textDecoration: "none", }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block", marginLeft: "1rem", marginRight: "1rem", ":hover": { color: "#EB9B29" }, color: pathname === "/ourwork" ? "#EB9B29" : "#14213D", }}>
                  <Typography textTransform={"capitalize"} >{t('ourwork')}</Typography>
                </Button>
              </Link>
              <Link to="/howwework" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block", marginLeft: "1rem", marginRight: "1rem", ":hover": { color: "#EB9B29" }, color: pathname === "/howwework" ? "#EB9B29" : "#14213D", }}>
                  <Typography textTransform={"capitalize"} >{t('howwework')}</Typography>
                </Button>
              </Link>
              <Link to="/aboutus" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, display: "block", marginLeft: "1rem", marginRight: "1rem", ":hover": { color: "#EB9B29" }, color: pathname === "/aboutus" ? "#EB9B29" : "#14213D", }}>
                  <Typography textTransform={"capitalize"} >{t('aboutus')}</Typography>
                </Button>
              </Link>
              <Grid sx={{ my: 2, display: "block", marginLeft: "1rem", marginRight: "1rem", }}>
                <LanguageSwitcher />
              </Grid>

              <Grid sx={{ flexGrow: 0 }}>
                <Tooltip title="Open Mail">
                  <Button
                    sx={{
                      width: "100px",
                      borderRadius: "30px",
                      textTransform: "capitalize",
                      padding: "10px",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      marginLeft: "1rem", marginRight: "2rem", marginBottom: "1rem",
                      elevation: 0
                    }}
                    onClick={() =>
                      (window.location = "mailto:info@oxide-digital.com")
                    }
                    variant="contained"
                    disableElevation>
                    <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                      Mail Us
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>


            </Menu>
          </Box>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Grid
              component="img"
              sx={{
                height: 30,
                maxHeight: { xs: 40, md: 100 },
                maxWidth: { xs: 100, md: 100 },
                display: { xs: "flex", md: "none" },
                mr: { xs: 7, sm: 30 },
              }}
              alt="Oxide Digital Technology"
              src="/assets/oxide/oxidelogowidelight.svg"
            />
          </Link>

          <Grid container justifyContent={"center"} sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,

                  marginLeft: "2rem",
                  marginRight: "2rem",
                  ":hover": { color: "#EB9B29" },
                  color: pathname === "/" ? "#EB9B29" : "white", // Add conditional styling
                }}
              >
                <Typography textTransform={"capitalize"}>{t('home')}</Typography>
              </Button>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, marginLeft: "2rem", marginRight: "2rem", ":hover": { color: "#EB9B29" }, color: pathname === "/services" ? "#EB9B29" : "white", }}>
                <Typography textTransform={"capitalize"} >{t('services')}</Typography>
              </Button>
            </Link>
            <Link to="/ourwork" style={{ textDecoration: "none", marginLeft: "2rem", marginRight: "2rem", }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, ":hover": { color: "#EB9B29" }, color: pathname === "/ourwork" ? "#EB9B29" : "white", }}>
                <Typography textTransform={"capitalize"} >{t('ourwork')}</Typography>
              </Button>
            </Link>
            <Link to="/howwework" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, marginLeft: "2rem", marginRight: "2rem", ":hover": { color: "#EB9B29" }, color: pathname === "/howwework" ? "#EB9B29" : "white", }}>
                <Typography textTransform={"capitalize"} >{t('howwework')}</Typography>
              </Button>
            </Link>
            <Link to="/aboutus" style={{ textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, marginLeft: "2rem", marginRight: "2rem", ":hover": { color: "#EB9B29" }, color: pathname === "/aboutus" ? "#EB9B29" : "white", }}>
                <Typography textTransform={"capitalize"} >{t('aboutus')}</Typography>
              </Button>
            </Link>

          </Grid>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <LanguageSwitcher />
          </Box>
          <Grid sx={{ flexGrow: 0 }}>
            <Tooltip title="Open Mail">
              <Button
                onClick={() =>
                  (window.location = "mailto:info@oxide-digital.com")
                }
                variant="contained"
                sx={{
                  width: "100px",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  padding: "10px",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  backgroundColor: "white",
                  ":hover": { color: "white", backgroundColor: "#EB9B29" }

                }} disableElevation>
                <Typography variant="caption" sx={{ fontWeight: "bold", color: "#3B4069", ":hover": { color: "white", backgroundColor: "#EB9B29" } }}>
                  {t('contactus')}
                </Typography>
              </Button>
            </Tooltip>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppNavigationBar;
