import { Box, Container, Grid, Typography } from "@mui/material";
import TechToolTabs from "../components/techtooltabs";
import { useTranslation } from 'react-i18next';

export default function SectionThree() {
    const { t } = useTranslation('AboutUsPage');
    return (
        <>
            <div style={{
                paddingTop: "6rem",
                paddingBottom: "8rem",
                backgroundColor: "#1A2E4A",
            }}>
                <Grid container justifyContent={"center"} >
                    <Typography sx={{
                        fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                        fontWeight: "bold", textAlign: "center", marginBottom: "1.2rem",
                        color: "#F8F9FF"
                    }} > {t('aboutussectiontitle3')}</Typography>
                </Grid>

                <Container>
                    <Box>
                        <Grid container justifyContent={"space-evenly"} >
                            <TechToolTabs />
                        </Grid>
                    </Box>
                </Container>
            </div>
        </>
    )

}