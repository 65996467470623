import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

export function Sectionone() {
    const { t } = useTranslation('AboutUsPage');
    return (
        <div style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
            backgroundColor: "#FFFBF6"
        }}>

            <Grid container justifyContent={"space-between"}>
                <Grid sx={{ paddingLeft: { xs: "3.4rem", md: 0 } }}>
                    <img src="./assets/aboutus/businesswithcoffee.png" style={{ maxWidth: "80%", boxShadow: "none", maxHeight: 420, marginBottom: "2rem", }} alt="about us" />
                </Grid>
                <Grid sx={{ maxWidth: { "xs": "100%", "sm": "52%", "md": "55%", "lg": "55%" }, boxShadow: "none" }} >
                    <Typography sx={{
                        fontSize: { "md": "1.3rem", "xs": "1.2rem" },
                        fontWeight: "bold", marginBottom: "0.0rem",
                        color: "#14213D"
                    }} >{t('aboutustittle')}</Typography>
                    <Typography sx={{ color: "#525252" }}>
                        <p>{t('aboutusparagraph1')}</p>
                        <p>{t('aboutusparagraph2')} </p>
                        <p>{t('aboutusparagraph3')} </p>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}