
import { Container, Grid, Typography } from "@mui/material";
import Techcard from "../components/techcard";
import { useTranslation } from 'react-i18next';


export default function Sectiontwo() {
    const { t } = useTranslation();
    return (
        <>
            <div style={{
                width: "100%",
                paddingTop: "5rem",
                textAlign: "center",
                paddingBottom: "5rem"

            }}>
                <Container maxWidth="lg" >
                    <Container maxWidth="sm">
                        <Grid container justifyContent={"center"} >
                            <Typography sx={{
                                fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                                fontWeight: "bold", textAlign: "center", marginBottom: "1.8rem",
                                color: "#14213D"
                            }} > {t('sectiontwotitle')}</Typography>
                        </Grid>
                    </Container>
                    <Grid container justifyContent={"space-between"}>
                        <Techcard />
                    </Grid>
                </Container>
            </div>

        </>
    )

}