import * as React from 'react';
import { useState } from 'react';
import { Chip, Box, useMediaQuery, useTheme } from '@mui/material';
import TechToolCard from './../components/techtoolcard';
import stackData from './../aboutusdatajson/stackdata.json';
import { useTranslation } from 'react-i18next';

export default function TechToolTabs() {
    const { t } = useTranslation('AboutUsPage');
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2, marginBottom: "30px" }}>
                {stackData && stackData.map((item, index) => (
                    <Chip
                        label={t(item.name)}
                        key={index}
                        clickable
                        disableElevation
                        disableRipple
                        variant={value === index ? "default" : "outlined"}
                        sx={value === index ? {
                            color: '#14213D',
                            backgroundColor: '#FFFFFF',
                            fontWeight: 'bold',
                            '&.MuiChip-root:hover': {
                                backgroundColor: '#FFFFFF',
                                color: '#14213D'
                            }
                        } : {
                            color: '#FFFFFF',
                            borderColor: '#FFFFFF'
                        }}
                        onClick={() => handleChange(index)}
                    />
                ))}
            </Box>
            {
                stackData && stackData.map((item, index) => (
                    value === index &&
                    <Box key={index} display="flex" flexDirection={isSmallScreen ? "column" : "row"} flexWrap="wrap" gap={2}>
                        {item.content.map((contentItem, contentIndex) => (
                            <TechToolCard key={contentIndex} data={contentItem} />
                        ))}
                    </Box>
                ))
            }
        </div >
    );
}