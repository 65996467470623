import { Container, Grid } from "@mui/material";
import { Sectionone } from "./sections/Sectionone";
import { Sectiontwo } from "./sections/Sectiontwo";
import SectionThree from "./sections/sectionthree";
import { Helmet } from "react-helmet-async";


export function AboutUs() {
    return (
        <>
            <Helmet>
                <title>About Us</title>
                <meta name="description"
                    content="Oxide Digital Technology is a Congolese start-up established in Bukavu by a group of young, skilled entrepreneurs."
                    data-rh="true" />
                <link rel="canonical" href="/aboutus"></link>
            </Helmet>
            <div>
                <Grid justifyContent={"center"} sx={{ backgroundColor: "#FFFBF6" }}>
                    <Container maxWidth="lg">
                        <Sectionone />
                    </Container>
                </Grid>
                <Grid justifyContent={"center"} sx={{ backgroundColor: "#F8F9FF" }}>
                    <Container maxWidth="lg">
                        <Sectiontwo />
                    </Container>
                </Grid>
                <Grid justifyContent={"center"} sx={{ backgroundColor: "#1A2E4A" }}>
                    <Container maxWidth="lg">
                        <SectionThree />
                    </Container>
                </Grid>
            </div>
        </>
    )
} 