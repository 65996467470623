import { Container, Grid } from "@mui/material";
import PortfolioCard from "../home/components/portfoliocard";
import { Helmet } from "react-helmet-async";

export function OurWork() {
    return (
        <>
            <Helmet>
                <title>Projects we've completed</title>
                <meta name="description"
                    content="Behind each innovative tool stands our dedicated team, striving for excellence. Every project we've embarked upon reflects our commitment to innovation and quality"
                    data-rh="true" />
                <link rel="canonical" href="/ourwork"></link>
            </Helmet>
            <div style={{
                paddingTop: "5rem",
                paddingBottom: "5rem"
            }}>
                <Container maxWidth="lg">
                    <Grid container justifyContent={"center"} >
                        <PortfolioCard />
                    </Grid>
                </Container>
            </div>
        </>
    )
} 