
import { Container, Grid, Typography } from "@mui/material";
import OurTimeline from "./components/Ourtimeline";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

export function HowWeWork() {
    const { t } = useTranslation('HowWeWorkPage');
    return (
        <>
            <Helmet>
                <title>Work Process</title>
                <meta name="description"
                    content="Delivering efficient results and timely completion of projects are top priorities. We adhere to a strict work process"
                    data-rh="true" />
                <link rel="canonical" href="/howwework"></link>
            </Helmet>
            <div style={{
                width: "100%",
                paddingTop: "4rem",
                paddingBottom: "4rem"

            }}><Grid container justifyContent={"center"} >
                    <Typography sx={{
                        fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                        fontWeight: "bold", textAlign: "center", marginBottom: "0.8rem",
                        color: "#14213D"
                    }} >{t('howweworkpagetittle')}</Typography>
                </Grid>
                <Grid container justifyContent={"center"} >
                    <Container maxWidth="sm">
                        <Typography sx={{
                            textAlign: "center", marginBottom: "1.8rem",
                            color: "#707070"
                        }} >{t('howweworkpagesubtittle')}</Typography>
                    </Container>
                </Grid>
                <Container>
                    <OurTimeline />
                </Container>
            </div>



        </>

    )
} 