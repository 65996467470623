import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Carddata from "./../datajson/Techcarddata.json"
import { useTranslation } from 'react-i18next';

export default function Techcard() {
  const { t } = useTranslation();
  return (
    <>
      {Carddata && Carddata.map(carddata => {
        return (
          <Card sx={{ maxWidth: { "xs": "100%", "sm": "33%", "md": "33%", "lg": "32%" }, boxShadow: "none", textAlign: "center", maxHeight: "300", marginTop: "1rem" }} key={carddata.id}>
            <img src={carddata.icon} style={{ height: "68px" }} alt='Card Icon' />
            <CardContent>
              <Typography gutterBottom variant="body1" component="div" fontWeight={"Bold"} color={"#14213D"}>
                {t(carddata.name)}
              </Typography>
              <Typography variant="body1" color="text.secondary" justifyContent={"center"} sx={{ paddingLeft: { md: 1, sm: 1 }, paddingRight: { md: 1, sm: 1 } }}>
                {t(carddata.description)}
              </Typography>
            </CardContent>
          </Card>
        )
      })}
    </>
  );
}
