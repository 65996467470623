import { Container, Grid, Typography, List, ListItem, } from "@mui/material";
import { Helmet } from "react-helmet-async";



export function MobilePolicies() {
    return (
        <>
            <Helmet>
                <title>Mobile App Policies</title>
                <meta name="description"
                    content="Oxide Digital Technology is a Congolese start-up established in Bukavu by a group of young, skilled entrepreneurs."
                    data-rh="true" />
                <link rel="canonical" href="/aboutus"></link>
            </Helmet>
            <div>
                <Grid justifyContent={"center"} >
                    <Container maxWidth="md" sx={{ paddingTop: "4rem", paddingBottom: "4rem", }}>

                        <Typography variant="h4" gutterBottom>
                            Privacy Policy for Oxide Digital Technology
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Oxide Digital Technology operates the mobile application services.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                            Information Collection and Use
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We collect several different types of information for various purposes to provide and improve our Service to you.
                        </Typography>

                        <Typography variant="body1" fontWeight={"bold"} gutterBottom>
                            Types of Data Collected
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Personal Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                        </Typography>
                        <List>
                            <ListItem>Name</ListItem>
                            <ListItem>Email address</ListItem>
                            <ListItem>User IDs</ListItem>
                            <ListItem>Address</ListItem>
                            <ListItem>Phone number</ListItem>
                            <ListItem>Photos</ListItem>
                            <ListItem>Other forms of data (please specify if any)</ListItem>
                        </List>

                        <Typography variant="h6" gutterBottom>
                            Usage Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We may also collect information that your device sends whenever you use our Service or when you access the Service by or through a mobile device ("Usage Data"). This Usage Data may include information such as:
                        </Typography>
                        <List>
                            <ListItem>Device or other IDs</ListItem>
                            <ListItem>Crash logs</ListItem>
                            <ListItem>Diagnostics</ListItem>
                            <ListItem>Other app performance data</ListItem>
                        </List>
                        <Typography variant="body1" paragraph>
                            We do not collect information about your precise or approximate location.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Use of Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Oxide Digital Technology uses the collected data for various purposes:
                        </Typography>
                        <List>
                            <ListItem>To provide and maintain our Service</ListItem>
                            <ListItem>To notify you about changes to our Service</ListItem>
                            <ListItem>To allow you to participate in interactive features of our Service when you choose to do so</ListItem>
                            <ListItem>To provide customer support</ListItem>
                            <ListItem>To gather analysis or valuable information so that we can improve our Service</ListItem>
                            <ListItem>To monitor the usage of our Service</ListItem>
                            <ListItem>To detect, prevent and address technical issues</ListItem>
                        </List>

                        <Typography variant="h6" gutterBottom>
                            Transfer of Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Oxide Digital Technology will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Disclosure of Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We do not share your personal information or data with third parties outside of Oxide Digital Technology. The data we collect is solely for the purposes of providing and improving our Service.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Data Protection
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The protection of your data is a priority for us. Although no method of transmission over the Internet or electronic storage is completely secure, we strive to use commercially acceptable means to protect your Personal Data. We maintain physical, electronic, and procedural safeguards that comply with federal standards to guard your nonpublic personal information.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Data Retention
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Data Access and Control
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You have certain data protection rights. Oxide Digital Technology aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. The rights include:
                        </Typography>
                        <List >
                            <ListItem>
                                The right to access, update, or delete the information we have on you.
                            </ListItem>
                            <ListItem>
                                The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.
                            </ListItem>
                            <ListItem>
                                The right to object. You have the right to object to our processing of your Personal Data.
                            </ListItem>
                            <ListItem>
                                The right of restriction. You have the right to request that we restrict the processing of your personal information.
                            </ListItem>
                            <ListItem>
                                The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.
                            </ListItem>
                        </List>
                        <Typography variant="body1" paragraph>
                            Please note that we may ask you to verify your identity before responding to such requests.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Service Providers
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We may employ third-party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Links to Other Sites
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Commitment to Children's Privacy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We recognize the importance of safeguarding the privacy of children. Our Service is not intended for individuals under the age of 13. Consistent with our commitment to protect the privacy interests of younger internet users, we do not knowingly collect or retain any personal information from children under the age of 13. Furthermore, our Service, including our website and mobile application, is designed and structured to avoid the unintentional attraction or interaction with minors under the specified age. If we learn that we have inadvertently gathered personal data from a child under 13, we will take prompt action to remove such data from our records. If parents or guardians become aware of their child providing us with personal information without their consent, they should contact us immediately so that we can take appropriate action.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Continuity of Policy Content
                        </Typography>
                        <Typography variant="body1" paragraph>
                            It is our intent to provide a clear and continuous flow of information so that every user can easily understand our practices regarding data management. We commit to regular reviews and updates of our Privacy Policy to ensure it reflects the latest in our data practices and complies with all regulatory requirements.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Professional Tone
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We present this Privacy Policy with a commitment to professionalism and transparency. We understand the importance of privacy and strive to communicate our policies with clarity and respect for the users who entrust us with their personal information. It is our goal that this document serves not only as a legal requirement but also as a testament to our dedication to our users' security and their rights to privacy.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Legal Compliance
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We have prepared this Privacy Policy with a focus on adhering to the highest standards of data protection and privacy laws. However, this document does not constitute legal advice, and we recommend that you consult with a legal professional to ensure that your specific circumstances are fully compliant with all applicable laws and regulations. It is the responsibility of the user to ensure that our services and the use of our user data align with the legal frameworks relevant to their region or jurisdiction.
                        </Typography>

                    </Container>
                </Grid>

            </div>
        </>
    )
} 