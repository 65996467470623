import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';



export default function TechToolCard({ data }) {
  const { t } = useTranslation('TechWeUse');
  return (
    <Card sx={{ maxWidth: { "xs": "100%", "sm": "48%", "md": "30%", "lg": "32%" }, maxHeight: 136, backgroundColor: "#14213D", borderRadius: "6px" }}>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <Grid>
            <Box
              component="img"
              sx={{
                flexGrow: 1,
                height: 40,
                width: 50,
              }}
              alt={data.name}
              src={data.icon}

            />
          </Grid>
          <Grid>
            <Typography variant="body1" component="div" fontWeight={"bold"} color={"#D08200"} gutterBottom>
              {t(data.name)}
            </Typography>
            <Typography variant="body2" color="#F8F9FF" >
              {t(data.description)}
            </Typography>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}