import { Route, Routes } from "react-router-dom";
import "./App.css";
import './i18n';
import Home from "./home/Home";
import AppNavigationBar from "./widgets/AppNavigationBar";
import Footer from "./widgets/Footer";
import { HowWeWork } from "./howwework/HowWeWork";
import { OurWork } from "./ourwork/OurWork";
import { Services } from "./services/Services";
import { AboutUs } from "./aboutus/AboutUs";
import { MobilePolicies } from "./privacymobile/mobilepolicies";
import { ProjectDetails } from "./projectdetails/ProjectDetails"
import { NotFound } from "./errorpages/NotFound";

function App() {
  return (
    <div className="App">
      <AppNavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/howwework" element={<HowWeWork />} />
        <Route path="/ourwork" element={<OurWork />} />
        <Route path="/services" element={<Services />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/mobilepolicies" element={<MobilePolicies />} />
        <Route path="/project/:id" element={<ProjectDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
