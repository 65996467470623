
import TechServiceCard from "./components/techservicecard";
import Sectionfive from "../home/sections/sectionfive";
import { Helmet } from "react-helmet-async";

export function Services() {
    return (
        <>
            <Helmet>
                <title>Services</title>
                <meta name="description"
                    content="We actively invest in developing innovative digital technologies that address real-world needs and solve pressing problems in the marketplace"
                    data-rh="true" />
                <link rel="canonical" href="/services"></link>
            </Helmet>
            <div>
                <TechServiceCard />
                <Sectionfive />
            </div>
        </>
    )
} 