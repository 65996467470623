import { Container } from "@mui/material";
import HeroSection from "./sections/herosection";
import Sectiontwo from "./sections/sectiontwo";
import Sectionthree from "./sections/sectionthree";
import Sectionfour from "./sections/sectionfour";
import Sectionfive from "./sections/sectionfive";
import { Helmet } from "react-helmet-async";


export default function Home() {
    return (
        <>
            <Helmet>
                <title>Oxide Digital Technology</title>
                <meta name="description"
                    content="Oxide provides services to small, medium and large companies. Moreover, we also develop and invest in viable digital technologies to meet marketplace needs"
                    data-rh="true" />
                <link rel="canonical" href="/"></link>
            </Helmet>
            <HeroSection />
            <br />
            <Container maxWidth="lg">
                <Sectiontwo />
            </Container>
            <Sectionthree />
            <Sectionfour />
            <Sectionfive />

        </>
    )
}