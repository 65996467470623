import { Box, Container, Grid, Typography } from "@mui/material";
import WorkWithUsCard from '../components/workwithuscard';
import { useTranslation } from 'react-i18next';

export default function Sectionfive() {
    const { t } = useTranslation();
    return (
        <>
            <div style={{
                paddingTop: "5rem",
                paddingBottom: "7rem",
                backgroundColor: "#f8f9ff",

            }}>
                <Grid container justifyContent={"center"} >
                    <Typography sx={{
                        fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                        fontWeight: "bold", textAlign: "center", marginBottom: "0.8rem",
                        color: "#14213D"
                    }} > {t('sectionfivetittle')}</Typography>
                </Grid>
                <Grid container justifyContent={"center"} >
                    <Container maxWidth="sm">
                        <Typography sx={{
                            textAlign: "center", marginBottom: "1.8rem",
                            color: "#707070"
                        }} > {t('sectionfivesubtittle')}</Typography>
                    </Container>
                </Grid>
                <Container>
                    <Box>
                        <Grid container justifyContent={"space-evenly"} >
                            <WorkWithUsCard />
                        </Grid>
                    </Box>

                </Container>
            </div >
        </>
    )

}