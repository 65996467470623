import { Container, Grid, Typography } from "@mui/material";
import SectorCard from "../components/sectorcard";
import { useTranslation } from 'react-i18next';

export function Sectiontwo() {
    const { t } = useTranslation('AboutUsPage');
    return (
        <div style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
        }}>
            <Container>
                <Typography sx={{
                    fontSize: { "md": "1rem", "xs": "1rem" },
                    fontWeight: "light", marginBottom: "0.3rem",
                    color: "#14213D",
                    textTransform: "uppercase"
                }}>{t('aboutustopnote')}</Typography>
                <Grid sx={{ boxShadow: "none" }} >
                    <Typography sx={{
                        fontSize: { "md": "1.3rem", "xs": "1.2rem" },
                        fontWeight: "bold", marginBottom: "2rem",
                        color: "#14213D"
                    }} >{t('aboutussectiontitle2')}</Typography>

                </Grid>
            </Container>
            <Grid container justifyContent="space-between">
                <SectorCard />
            </Grid>

        </div>
    )
}