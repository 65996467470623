import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Projectcarddata from "./../datajson/Portfoliocardata.json"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PortfolioCard() {
  const { t } = useTranslation('ProjectsDetails');
  return (
    <>
      {Projectcarddata && Projectcarddata.map(projectcarddata => {
        return (
          <Card sx={{ maxWidth: { "xs": "100%", "sm": "40%", "md": "30%", "lg": "30%" }, boxShadow: "none", maxHeight: "300", margin: "1rem" }} key={projectcarddata.id} >
            <Link to={`/project/${projectcarddata.id}`} style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="195"
                  image={projectcarddata.img}
                  alt="Completed Project"
                />
                <CardContent >
                  <Typography
                    variant="body2"
                    fontWeight={"Bold"}
                    color={"#14213D"}
                    sx={{ textAlign: "left", textDecoration: 'none !important' }}
                  >
                    {t(projectcarddata.name)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ textAlign: "left", }} >
                    {t(projectcarddata.description)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card >

        )
      })}
    </>
  );
}