import { Container, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const currentYear = new Date().getFullYear(); // Get the current year
  const { t } = useTranslation();
  return (

    <>
      <div
        style={{
          minHeight: "30dvh", display: "flex", flexDirection: "column",
          position: "static",
          bottom: 0,
          backgroundColor: "#14213D",
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid sx={{ maxWidth: 420, marginBottom: "2rem" }}>
              <Typography
                fontSize={{ xs: "1.4rem", md: "1.4rem" }}
                color="#EB9B29"
                fontWeight={"bold"}
              >
                Oxide Digital Technology
              </Typography>
              <Typography variant="subtitle2" color="#E5E5E5" fontWeight={400}>
                {t('footernote')}
              </Typography>
            </Grid>
            <Grid sx={{ minWidth: 232, maxWidth: 420, marginBottom: "2rem" }}>
              <Typography variant="body2" color="#E5E5E5" fontWeight={400}>
                RCCM: CK/BKV/RCCM/20-B-00095 <br />
                ID. NAT: 22-J6100-N70836G D-U-N-S
                <br /> Number:+243 851 754 060 <br />
                Email: info@oxide-digital.com
              </Typography>
            </Grid>
          </Grid>
          <Divider color="#D08200" sx={{ marginBottom: "2rem" }} />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid>
              <Typography variant="caption" color="grey">
                Copyright © {currentYear} Oxide Digital Technology Sarl. All
                rights reserved.
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption" color="grey">
                Privacy Policy
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}