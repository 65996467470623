import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Weworkdata from "./../wewokdatajson/weworkdata.json"
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Ourtimeline() {
    const { t } = useTranslation('HowWeWorkPage');
    return (
        <><div>
            <Grid sx={{ maxWidth: { "xs": "100%", "sm": "90%", "md": "90%", "lg": "90%" }, boxShadow: "none" }}>
                <Timeline position="right" >
                    {Weworkdata && Weworkdata.map(weworkdata => {
                        return (

                            <TimelineItem key={weworkdata.id}>
                                <TimelineOppositeContent
                                    sx={{ maxWidth: { "xs": "9%", "sm": "20%", "md": "20%", "lg": "20%" }, m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {t(weworkdata.phase)}
                                </TimelineOppositeContent>
                                <TimelineSeparator  >
                                    <TimelineConnector />
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography sx={{
                                        fontWeight: "bold", marginBottom: "1.8rem",
                                        color: "#14213D"
                                    }} component="span">
                                        {t(weworkdata.step)}
                                    </Typography >
                                    <Typography sx={{
                                        marginBottom: "1.8rem",
                                        color: "#707070"
                                    }} >{t(weworkdata.description)}</Typography>
                                </TimelineContent>
                            </TimelineItem>


                        )
                    })}
                </Timeline>
            </Grid>

        </div>
        </>
    );
}