import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import Sectorsdata from "./../aboutusdatajson/sectorsdata.json"
import { useTranslation } from 'react-i18next';


export default function SectorCard() {
  const { t } = useTranslation('AboutUsPage');
  return (
    <>
      {Sectorsdata && Sectorsdata.map(sector => {
        return (
          <div id={sector.id}>
            <Card sx={{ maxWidth: { sm: "380px", md: "275px", lg: "275px", xs: "350px" }, boxShadow: "none", maxHeight: 310, backgroundColor: "#F8F9FF", marginBottom: "2rem" }}>
              <CardContent>
                <Divider sx={{ borderBottomWidth: 1, borderColor: "#14213D", marginBottom: "10px" }} />
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t(sector.industry)}
                </Typography>
                <Typography variant="h6" component="div" fontWeight={"bold"} color={"#14213D"} gutterBottom>
                  {t(sector.title)}
                </Typography>
                <Typography variant="body1" color="text.secondary" >
                  {t(sector.description)}
                </Typography>
              </CardContent>
            </Card>
          </div>
        )
      })}

    </>
  );
}