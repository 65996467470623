import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [language, setLanguage] = React.useState(i18n.language);

    const changeLanguage = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Select
            value={language}
            onChange={changeLanguage}
            sx={{
                color: "white",
                '& .MuiSvgIcon-root': { color: 'white' },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                '@media (max-width:600px)': {
                    color: 'black',
                    '& .MuiSvgIcon-root': { color: 'black' }
                }
            }}
        >
            <MenuItem value='en'>en</MenuItem>
            <MenuItem value='fr'>fr</MenuItem>
        </Select>
    );
}