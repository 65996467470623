import { Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Sectionthree() {
    const { t } = useTranslation();
    return (
        <>
            <div style={{
                backgroundColor: "#f8f9ff",
                width: "100%",
                paddingTop: "5rem",
                textAlign: "center",
                paddingBottom: "5rem"

            }}>
                <Container maxWidth="sm">
                    <Grid container justifyContent={"center"} >
                        <Typography sx={{
                            fontSize: { "md": "1.9rem", "xs": "1.2rem" },
                            fontWeight: "bold", textAlign: "center", marginBottom: "0.8rem",
                            color: "#14213D"
                        }} >{t('sectionthreetittle')}</Typography>
                    </Grid>
                </Container>
                <Grid container justifyContent={"center"} >
                    <Container maxWidth="sm">
                        <Typography sx={{
                            textAlign: "center", marginBottom: "1.8rem",
                            color: "#707070"
                        }} > {t('sectionthreesubtittle')}</Typography>
                    </Container>
                </Grid>
                <Grid container justifyContent={"center"} >
                    <img src="./assets/home/oxipos.png" style={{ height: "25vw", marginBottom: "3rem" }} alt="recent Project" />
                </Grid>
                <Grid container justifyContent={"center"} >
                    <Link to="https://play.google.com/store/apps/details?id=com.oxidetech.oxipos&hl=en" target="_blank" style={{ textDecoration: "none", }}>
                        <Button variant="outlined" sx={{ borderRadius: 16, border: 1, textTransform: "none", fontWeight: "bold" }}>{t('sectionthreebutton')}</Button>
                    </Link>
                </Grid>
            </div>
        </>
    )

}