import { Grid, Typography, Container, Button } from "@mui/material";
// import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export function NotFound() {
    // const { t } = useTranslation('AboutUsPage');
    return (
        <Grid sx={{
            paddingTop: { xs: "2rem", md: "7rem" },
            paddingBottom: { xs: "2rem", md: "7rem" },
            backgroundColor: "#FFFBF6"
        }}><Container>


                <Grid container justifyContent={"space-between"}>
                    <Grid sx={{ paddingLeft: { md: 0 } }}>
                        <Typography variant="h5" sx={{ color: "#14213D", fontWeight: 600 }}>
                            Page Not Found!
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#525252", fontWeight: 400 }}>
                            Oops! It looks like you've taken a wrong turn. <br /> The page you're looking for might have been moved or doesn't exist.
                        </Typography>
                        <Grid style={{
                            marginTop: "1rem",
                        }}><Link to="/" style={{ textDecoration: "none" }} >
                                <Button variant="outlined" size="medium" sx={{
                                    textTransform: "none", backgroundColor: "#14213D", color: "#FFFFFF", '&:hover': {
                                        backgroundColor: '#14213D',
                                        borderColor: '#14213D',
                                        boxShadow: 'none',
                                    },

                                }}>
                                    Go Back to the Homepage
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid sx={{ boxShadow: "none" }} >

                        <Typography variant="h1" sx={{ marginTop: { xs: "2rem" }, color: "#D3D3D3", fontSize: { xs: "13.5rem", sm: "15rem" }, fontWeight: 600, lineHeight: "12rem" }}>
                            404
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}