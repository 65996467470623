import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, Container, Grid, Stack } from '@mui/material';
import SeviceCardData from "./../datajson/techservicedata.json"
import { useTranslation } from 'react-i18next';

export default function TechServiceCard() {
  const { t } = useTranslation('ServicesPage');
  return (
    <>
      {SeviceCardData && SeviceCardData.map(servicecarddata => {
        return (
          <div id={servicecarddata.id} style={{
            backgroundColor: servicecarddata.background, paddingTop: "5rem",
            paddingBottom: "5rem"
          }}>
            <Container maxWidth="md">
              <Grid container>
                <img src={t(servicecarddata.img)} style={{ maxWidth: 500, minWidth: 200, boxShadow: "none", maxHeight: 310, marginBottom: "3rem", marginTop: "2rem" }} alt='Services' />
              </Grid>

              <Grid container justifyContent={"space-between"} >

                {servicecarddata && servicecarddata.content.map(detaitls => {
                  return (
                    <Grid sx={{ maxWidth: { "xs": "100%", "sm": "50%", "md": "50%", "lg": "50%" }, boxShadow: "none" }}>
                      <Card sx={{ minWidth: 200, boxShadow: "none", maxHeight: 310 }} style={{ backgroundColor: servicecarddata.background }}>
                        <CardContent>
                          <Stack direction="row" spacing={2}>
                            <Grid>
                              <img src={detaitls.icon} style={{ height: "40px" }} alt='Card Icon' />
                            </Grid>
                            <Grid>
                              <Typography variant="body1" component="div" fontWeight={"bold"} color={"#14213D"} gutterBottom>
                                {t(detaitls.name)}
                              </Typography>
                              <Typography variant="body1" color="text.secondary" >
                                {t(detaitls.description)}
                              </Typography>
                            </Grid>
                          </Stack>
                        </CardContent>

                      </Card>
                    </Grid>)

                })}
              </Grid>
              <Grid container justifyContent={"center"} sx={{ marginTop: "2rem" }}>
                <Button
                  onClick={() =>
                    (window.location = "mailto:info@oxide-digital.com")
                  }
                  variant="outlined"
                  sx={{
                    borderRadius: 16,
                    border: 1,
                    textTransform: "none",
                    fontWeight: "bold"
                  }}>
                  Interested?
                </Button>
              </Grid>
            </Container>
          </div>
        )
      })}

    </>
  );
}