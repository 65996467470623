import React from 'react';
import { useParams } from 'react-router-dom';
import Projectcarddata from "./../home/datajson/Portfoliocardata.json"
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ProjectDetails() {
    const { id } = useParams();
    const project = Projectcarddata.find(p => p.id === Number(id));
    const { t } = useTranslation('ProjectsDetails');
    // Check if project exists
    if (!project) {
        return <div>Project not found</div>;
    }

    return (
        <div >

            {project.content && project.content.map((content, index) => (

                <div key={index}>
                    <div style={{
                        paddingTop: "5rem",
                        paddingBottom: "5rem",
                        backgroundColor: "#FFFBF6",
                    }}>
                        <Container>
                            <Grid container justifyContent={"space-between"}>
                                <Grid >
                                    <img src={`/assets/projects/project${id}.png`} style={{ maxWidth: 362, boxShadow: "none", maxHeight: 285, marginBottom: "2rem" }} alt="Completed Project" />
                                </Grid>
                                <Grid sx={{ maxWidth: { "xs": "100%", "sm": "52%", "md": "62%", "lg": "62%" }, boxShadow: "none" }} >
                                    <Typography sx={{
                                        fontSize: { "md": "1.3rem", "xs": "1.2rem" },
                                        fontWeight: "bold", marginBottom: "0.3rem",
                                        color: "#14213D"
                                    }} >{t(content.projectname)} </Typography>
                                    <Typography sx={{ color: "#707070", marginBottom: "0.9rem" }}> {t(content.aboutproject)}</Typography>
                                    {content.tech && content.tech.length > 0 && (
                                        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                            <Typography variant="subtitle2" sx={{ color: "#14213D", fontWeight: "bold", marginBottom: "0.3rem" }}> This project is available on:</Typography>

                                            {content.tech.map((tech) => (
                                                <React.Fragment key={tech.id}>
                                                    <a href={tech.webapplink} target="_blank" rel="noopener noreferrer">
                                                        <img src={tech.webapp} alt='' style={{ boxShadow: "none", maxHeight: "2.7rem", marginRight: "0.3rem" }} />
                                                    </a>
                                                    <a href={tech.appstorelink} target="_blank" rel="noopener noreferrer">
                                                        <img src={tech.appstore} alt='' style={{ boxShadow: "none", maxHeight: "2.7rem", marginRight: "0.3rem" }} />
                                                    </a>
                                                    <a href={tech.googleplaylink} target="_blank" rel="noopener noreferrer">
                                                        <img src={tech.googleplay} alt='' style={{ boxShadow: "none", maxHeight: "2.7rem", marginRight: "0.3rem" }} />
                                                    </a>

                                                </React.Fragment>
                                            ))}
                                        </div>
                                    )}

                                </Grid>
                            </Grid>
                        </Container>
                    </div>


                    <div style={{
                        paddingTop: "4rem",
                        paddingBottom: "3rem",
                        backgroundColor: "#f8f9ff",
                    }}>
                        <Container>
                            <Typography variant="caption" sx={{ color: "#14213D", fontWeight: "bold", textTransform: "uppercase" }}> The implemented solution:</Typography>
                            {content.solutions && content.solutions.map((solution, solutionIndex) => (
                                <Grid style={{
                                    paddingTop: "2rem",
                                    paddingBottom: "3rem",
                                }}
                                    key={solutionIndex} container justifyContent={"space-between"}>
                                    <Grid sx={{ paddingLeft: { xs: "0", } }} justifyContent="center">
                                        <img src={solution.image} style={{ maxWidth: 362, boxShadow: "none", maxHeight: 240, marginTop: "3rem", marginBottom: "1rem", }} alt="Solution 1" />
                                    </Grid>
                                    <Grid sx={{ maxWidth: { "xs": "100%", "sm": "52%", "md": "62%", "lg": "62%" }, boxShadow: "none" }} >
                                        <Typography sx={{ color: "#EB9B29" }}> {t(solution.order)}</Typography>
                                        <Typography sx={{
                                            fontSize: { "md": "1.3rem", "xs": "1.2rem" },
                                            fontWeight: "bold", marginBottom: "0.3rem",
                                            color: "#14213D"
                                        }} >{t(solution.title)} </Typography>
                                        <Typography sx={{ color: "#707070" }}> {t(solution.details)}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Container>
                    </div>

                </div>
            ))}
        </div>
    );
}

export { ProjectDetails };