import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enHome from "./locales/en/HomePage.json";
import frHome from "./locales/fr/HomePage.json";
import enProjects from "./locales/en/ProjectsDetails.json";
import frProjects from "./locales/fr/ProjectsDetails.json";
import enAboutUs from "./locales/en/AboutUsPage.json";
import frAboutUs from "./locales/fr/AboutUsPage.json";
import enServices from "./locales/en/ServicesPage.json";
import frServices from "./locales/fr/ServicesPage.json";
import enHowwework from "./locales/en/HowWeWorkPage.json";
import frHowwework from "./locales/fr/HowWeWorkPage.json";
import enAppBar from "./locales/en/AppBar.json";
import frAppBar from "./locales/fr/AppBar.json";
import enTechWeUse from "./locales/en/TechWeUse.json";
import frTechWeUse from "./locales/fr/TechWeUse.json";


const browserLang = navigator.language || navigator.userLanguage;
const language = browserLang.split('-')[0]; // Get language code

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                HomePage: enHome,
                ProjectsDetails: enProjects,
                AboutUsPage: enAboutUs,
                ServicesPage: enServices,
                HowWeWorkPage: enHowwework,
                AppBar: enAppBar,
                TechWeUse: enTechWeUse
            },
            fr: {
                HomePage: frHome,
                ProjectsDetails: frProjects,
                AboutUsPage: frAboutUs,
                ServicesPage: frServices,
                HowWeWorkPage: frHowwework,
                AppBar: frAppBar,
                TechWeUse: frTechWeUse
            }
        },
        lng: language,
        fallbackLng: "en",
        ns: ['HomePage', 'ProjectsDetails', 'AboutUsPage', 'ServicesPage', 'HowWeWorkPage', 'AppBar', 'TechWeUse'],
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;